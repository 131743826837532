var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 卡罗莱纳数据 ")]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{ emptyText: '暂无数据' },"scroll":{
        x: true
      },"pagination":{
        total: _vm.total,
        current: _vm.pageNumber,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}},_vm._l((_vm.scopeds),function(key,index){return {key:key,fn:function(item, row, i){return [_c('div',{staticClass:"box"},_vm._l((_vm.exportList(item,key)),function(ite,index){return _c('div',{key:index,staticClass:"colorBox",style:({backgroundColor: ite.value == 100?_vm.colorData[0]:''})},[_c('p',[_vm._v(_vm._s(ite.key))]),(ite.value == 50)?_c('img',{staticClass:"colorBox1",attrs:{"src":require("@/assets/image/KLLN/estimateFirstOne.png"),"alt":""}}):_vm._e()])}),0)]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }