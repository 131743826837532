<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 卡罗莱纳数据 </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :scroll="{
          x: true
        }"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <template v-for="(key,index) in scopeds" :slot="key" slot-scope="item, row, i">
          <div class="box">
            <div class="colorBox" :style="{backgroundColor: ite.value == 100?colorData[0]:''}" v-for="(ite,index) in exportList(item,key)" :key="index">
              <p>{{ite.key}}</p>
              <!-- <div class="colorBox1"></div> -->
              <img src="@/assets/image/KLLN/estimateFirstOne.png" v-if="ite.value == 50" alt="" class="colorBox1"/>
              <!-- <div class="colorBox2"></div> -->
              <!-- <img src="@/assets/image/KLLN/estimateSecendTwo.png" alt="" class="colorBox2"/> -->
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    // 课程类型
    title: "课程序列",
    align: "center",
    dataIndex: "type",
    colSpan: 2,
    width: 18,
    customRender: null,
    fixed: 'left'
  },
  {
    align: "left",
    dataIndex: "name",
    colSpan: 0,
    width: 200,
    fixed: 'left'
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import Province from "@/components/provinceCity";
import tableData from "./data.js"
export default {
  // 可用组件的哈希表
  components: { HeaderBox, Province },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      state: 1, // 1新建，2编辑
      visible: false,
      columns, // 表头
      scopeds: [],
      tableData, // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      // 发货省市区
      shippingAddressValue: [],
      // 新建时数据
      formOBJ: {
        province: null,
        city: null,
        area: null,
        address: "",
        mobile: "",
        name: "",
      },
      PreventLoad: false,
      colorData: ['#D0FC73','#FEE477','#8ABFFE']
    };
  },
  // 事件处理器
  methods: {
    // 返回对应数据
    exportList(item,key) {
      let arr = null;
      item.date?.map(item=>{
        if(item.key == key) {
          arr = item.value;
        }
      })
      return arr;
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      // 获取表头列表
      this.scopeds = [];
      let columns = this.tableData[0].date?.map(item=>{
        this.scopeds.push(item.key);
        return {
          title: item.key,
          align: "center",
          scopedSlots: { customRender: item.key }
        };
      })
      console.log(columns)
      this.columns.push(...columns);
      // this.$ajax({
      //   url: "/hxclass-management/admin-address/list",
      //   method: "get",
      //   params: {
      //     pageNum: this.pageNumber,
      //     pageSize: this.pageSize,
      //     // keyWord: this.name,
      //   },
      // }).then((res) => {
      //   // console.log(res);
      //   if (res.code == 200 && res.success) {
      //     this.tableData = res.data.records;
      //     this.total = res.data.total;
      //   }
      // });
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getdataList();
    this.columns[0].customRender = (value, row, index) => {
      const obj = {
        children: value,
        attrs: {},
      };
      let typeArr = this.tableData.filter(item=>{ return item.type == row.type});
      if(index == 0) {
        obj.attrs.rowSpan = typeArr.length;
      }else {
        if (this.tableData[index-1].type == row.type) {
          obj.attrs.rowSpan = 0;
        }else {
          obj.attrs.rowSpan = typeArr.length;
        }
      }
      return obj;
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.box{
  text-align: center;
  width:300px;
  margin: 0 auto;
  display: flex;
  .colorBox{
    flex: 1;
    // border: 1px solid #ccc;
    position: relative;
    border: 1px solid #ffffff;
    p{
      position: relative;
      z-index: 1;
    }
    .colorBox1{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .colorBox2{
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    // width: 0;
    // height: 0;
    // border: 100% solid;
    // border-color: orangered gold;
  }
  // .colorBox:last-child{
  //   border: 0;
  // }
}
.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}

/* 表格样式 */
// 奇数行
/deep/.ant-table-row:nth-child(2n+1) {
  background-color: #F2F8FF;
}
/deep/.ant-table-row:nth-child(2n) {
  background-color: #F4F5FA;
}
/deep/.ant-table-tbody{
  td{
//  margin: 4px !important;
 border: 2px solid #ffffff;
  }
}
</style>
