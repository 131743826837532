export default [
  {
    name: '1. 自我管理和责任',
    type: '自我|社交',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 100
          },{
            key: 'c',
            value: 100
          },{
            key: 'c',
            value: 100
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          }
        ]
      }
    ]
  },
  {
    name: '2. 自我管理和责任',
    type: '自我|社交',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 50
          },{
            key: 'c',
            value: 100
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 50
          },{
            key: 'e',
            value: 100
          }
        ]
      }
    ]
  },
  {
    name: '2. 自我管理和责任',
    type: '自我|社交',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 50
          },{
            key: 'c',
            value: 100
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 50
          },{
            key: 'e',
            value: 100
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  },
  {
    name: '3. 自我管理和责任',
    type: '沟通/实践',
    date: [
      {
        key: '0-3个月',
        value: [
          {
            key: 'a',
            value: 100
          },{
            key: 'b',
            value: 100
          },{
            key: 'c',
            value: 50
          }
        ]
      },{
        key: '4-6个月',
        value: [
          {
            key: 'd',
            value: 100
          },{
            key: 'e',
            value: 100
          },{
            key: 'f',
            value: 50
          }
        ]
      }
    ]
  }
]

// [
//   {
//     type: '自我|社交',
//     date: [
//       {
//         name: '1. 自我管理和责任',
//         date: [
//           {
//             key: '0-3个月',
//             value: [
//               {
//                 key: 'a',
//                 value: 100
//               },{
//                 key: 'b',
//                 value: 100
//               },{
//                 key: 'c',
//                 value: 100
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   }
// ]